import Card from 'react-bootstrap/Card';

const TimeoutCard = ({ttGroup}) => {

    const LFhelpdeskLink = 'https://leapfroghelpdesk.zendesk.com/hc/en-us'

    console.log("ttGroup", ttGroup)

    if (ttGroup === "LF") {
      return (
        <Card className="mt-3">
            <Card.Header>
            Timed out of Test (Publicly reported as Incomplete Evaluation):
            </Card.Header>
            <Card.Body style={{ backgroundColor: "white" }}>
            <p><strong>This hospital did not complete the CPOE Evaluation Tool within the allotted time. The hospital is eligible to retake the test in 120 days. Please contact the <a href={LFhelpdeskLink}>Leapfrog Help Desk</a> for further assistance.</strong></p>
            </Card.Body>
        </Card>
        )
    }

    return (
        <Card className="mt-3">
            <Card.Header>
            Timed out of Test (Publicly reported as Incomplete Evaluation):
            </Card.Header>
            <Card.Body style={{ backgroundColor: "white" }}>
            <p><strong>This hospital did not complete the CPOE Evaluation Tool within the allotted time. The hospital is eligible to retake the test in 120 days. Please contact <a href="mailto: Helpdesk_EHRSafetyEvalTool@utah.edu">Helpdesk_EHRSafetyEvalTool@utah.edu</a> for further assistance.</strong></p>
            </Card.Body>
        </Card>
        )
}

export default TimeoutCard