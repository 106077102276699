import React, { useState, useEffect, useContext } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from 'react-bootstrap/Button';
import 'jspdf-autotable'
import { UserContext } from "../context/UserContextProvider";
import { useHistory } from "react-router-dom";
import {sortPatients} from '../globals';
import Reminder from '../pages/tools/Reminder'
import RedText from "./tools/RedText";
import useDisableBackButton from "./hooks/useDisableBackButton";
// import useSetCurrentStep from "./hooks/useSetCurrentStep";

const { jsPDF } = require('jspdf'); 

const PatientsPDF = () => {

    const { accessCode, getApiConfig, setCurrentStep, isLeapFrogTest, isSampleTest, deadlineDate, testType,
        testContact, testFacilityName, sampleTestDeadlineDate, setStepNumber, currentTestStepNumber } = useContext(UserContext);

    const history = useHistory();

    const [patientsArray, setPatientsArray] = useState([]);
    const [patientsTable, setPatientsTable] = useState([]);
    const [medrecPatientsArray, setMedrecPatientsArray] = useState([]);
    const [medRecOrders, setMedRecOrders] = useState([]);
    // const [downloadedOnce, setDownloadedOnce] = useState(false)

    // loaded state
    const [loaded, setLoaded] = useState(false);

    // PDF title and datestamp for filename
    const title = `Enter the following patient information:`;
    const medRecTitle = `Enter the following Medication Reconciliation Patient Information:`;
    let dateString = new Date().toDateString().split(' ').join('_');

    useDisableBackButton() // Disable the browser's back button... like it says

    // 7/15/22 -> Setting the current step(which starts the client-side test timer) 
    // should happen AFTER fetching patients data(as this action sets the start time in the database)
    // best course of action is to have that code run here instead of in the custom hook so when it runs 
    // is easier to control

    useEffect(()=>{
        // fetch patients data
        if (patientsArray.length === 0) {
        fetch(getApiConfig("getSiteTestPatients"), {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json"
            },
            body: JSON.stringify({
                "access-code": accessCode,
            }),
          })
          .then((response) => response.json())
          .then((data) => {
            // The test data is URI encoded and JSON.stringified, first pull the patients object
            let patientsObject = JSON.parse(decodeURIComponent(data.data));
            // create an array of all patients fetched
            let fetchedPatientsArray = Object.entries(patientsObject[0].patients).map(patient => patient[1])
            // filter out med rec patients in order to add a second table if they exist
            // and remove them from the original patients array so as to not add them to the first patients table
            let nonMedRecPatients = fetchedPatientsArray.filter(patient => patient.patient_class !== '3' && patient.patient_class !== '4')
            let medRecPatients = fetchedPatientsArray.filter(patient => patient.patient_class === '3' || patient.patient_class === '4')
            // save the data in state, trigger useEffect to create the pdf table(s)
            setPatientsArray(nonMedRecPatients);
            setMedrecPatientsArray(medRecPatients)
          })
          .catch((error) => {
            console.error("Error:", error);
          }); 
        }
    }, [accessCode, getApiConfig, patientsArray]) 

    // replacing the usual run of useSetCurrentStep("PATIENT_PDF_REQUESTED", 1)
    useEffect(() => {
        if (patientsArray.length > 0) {
            const setStepOneAfterPatientsAreDownloaded = (step, stepNum) => {
                // sets current step, if successful sets step for UI/Context
                // console.log('running set step one function...');
                if (currentTestStepNumber !== stepNum && !isSampleTest) {
                    fetch(getApiConfig("updateCurrentSiteTestStep"), {
                        method: "POST",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json"
                        },
                        body: JSON.stringify({
                            "access-code": accessCode,
                            "ui-step": step
                        })})
                        .then((response) => response.json())
                        .then((data) => {
                        // console.log('update test step response data = ', data);
                        // console.log('post current step data.response = ', data.response);
                        if (data.response.includes('SUCCESS')) {
                          // setting step number will check and set time for test clock  
                          setStepNumber(stepNum)
                          console.log('setting context step # to ', stepNum);
                        }
                        })
                        .catch((error) => {
                        console.error("Error setting step 1:", error);
                        });
                }
            }
            
            setStepOneAfterPatientsAreDownloaded("PATIENT_PDF_REQUESTED", 1)
        }
    }, [accessCode, currentTestStepNumber, getApiConfig, isSampleTest, patientsArray, setStepNumber])

    // func to remove pipes from patient data strings
    const handleReplacePipe = (str) => {
        const pipeSpace = /\| /g
        const pipe = /\|/g
        let resultString = ''
        if (str.match(pipeSpace)) {
        resultString = str.replace(pipeSpace, '\n\n')
        } else if (resultString.match(pipe)) {resultString = str.replace(pipe, '\n\n')}
        else {resultString = str}
        // handle passing original string
        return resultString 
    }

    useEffect(() => { // create patients table
        if (patientsArray.length > 0) {
            // use lodash .orderBy() to sort patients in sequential order
            let sortedPatients = sortPatients(patientsArray);
            // Add an index for use in the PDFs
            sortedPatients.forEach((patient, i) => patient.pdfDisplayIndex = i + 1)
            // changed from printing Patient ID to instead number sequentially (ignoring the patient record_id)
            const patients = sortedPatients.map((patient, index) => {
                return ['', patient.pdfDisplayIndex, patient.age + " years", patient.sex === '2' ? 'F' : 'M', patient.weight + " " + (patient.weight_unit === '1' ? ' kg' : ' lbs'), 
                patient.height + " " + (patient.height_unit === '1' ? " cm" : ' in'), handleReplacePipe(patient.allergies), handleReplacePipe(patient.lab_values), patient.problem_diagnosis, patient.icd10_recommendations]
            })
            setPatientsTable(patients)
        }
    }, [patientsArray])
    

    useEffect(() => {
        if (medrecPatientsArray.length === 0) {setLoaded(true)}
        else {
            fetch(getApiConfig("getSiteTestOrders"), {
                method: "POST",
                mode: "cors",
                headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
                },
                body: JSON.stringify({
                    "access-code": accessCode,
                }),
            })
            .then((response) => response.json())
            .then((data) => {
                    let rawData = JSON.parse(decodeURIComponent(data.data));
                    // save the data in state, PDF not created until requested by the button
                    console.log('orders fetched for medRecPatient(s): ', rawData[1].orders);
                    // filter the MedRec orders, throw the rest into the sea
                    let medRecOrdersOnly = rawData[1].orders.filter(order => Number(order.order_category_name) >= 15 )
                    console.log('med rec orders filtered from all orders: ', medRecOrdersOnly);
                    setMedRecOrders(medRecOrdersOnly);
                    medRecOrdersOnly.length > 0 && setLoaded(true);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
        }
    }, [medrecPatientsArray, accessCode, getApiConfig])


    // helper function to replace commas used in 'order_set_desc' with different punctuation
    const createOrdersList = (orderList) => orderList.map(order => order.order_set_desc.toString().replace(',', ' | '));

    const addPageNumbers = (doc) => {
        const pages = doc.internal.getNumberOfPages();
        const pageWidth = doc.internal.pageSize.width;  //Optional
        const pageHeight = doc.internal.pageSize.height;  //Optional
        doc.setFontSize(10);  //Optional
                
        for (let j = 1; j < pages + 1 ; j++) {
            let horizontalPos = pageWidth / 2;  //Can be fixed number
            let verticalPos = pageHeight - 10;  //Can be fixed number
            doc.setPage(j);
            doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {align: 'center'});
        }
    } 

    // function for adding a cover page (LeapFrog only)
    const CoverSheetAndAddPage = (doc) => {
        const numPatients = medrecPatientsArray.length ? medrecPatientsArray.length + patientsArray.length : patientsArray.length 

        doc.setFontSize(24)
        doc.text(`Test Patients`, (doc.internal.pageSize.width / 2), 30, {align: 'center'})
        doc.setLineWidth(3);
        doc.line(30, 38, 812, 38)
        doc.setFontSize(16)
        doc.setFont('helvetica', 'bold');
        doc.text(`${isSampleTest ? 'Sample ' : ''}Adult ${testType} Test`, 62, 60)
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(11)
        doc.text(`You have until ${isSampleTest ? sampleTestDeadlineDate : deadlineDate} EDT to complete Step 1, Step 2, and then return to the CPOE Tool to start Step 3.`, 62, 74)
        doc.text(`Generated for: ${testContact !== "" ? (testContact + ` at `) : ''}${testFacilityName !== null ? testFacilityName : 'Hospital Name'}`, 62, 90)
        doc.text(`Number of patients: ${numPatients}`, 62, 106)
        doc.text(`Instructions for the test are available at: http://www.leapfroggroup.org/survey-materials/survey-and-cpoe-materials`, 62, 132)
        doc.text(`Review the instructions and requirements completely before taking the test and have them available while completing each step.`, 62, 158)

        doc.addPage()
    }

    // Paper size in points:  Default (A4) 595h x 842w
    const createPDF = (date) => {
        const doc = new jsPDF('l', 'pt');

        if (isLeapFrogTest) {CoverSheetAndAddPage(doc)}

        doc.text(title, 40, 25);

        doc.autoTable({ 
            headStyles: {fillColor: 'CornFlowerBlue'},
            head: [['Local ID', 'Pt.', 'Age', 'Sex', 'Weight', 'Height', 'Allergies', 'Lab Values', 'Problem/Diagnosis', 'ICD-10 Recommendation']],
            body: patientsTable,
            // below keeps the next row from being split between two pages
            rowPageBreak: 'avoid'
          })
        if (medrecPatientsArray.length > 0) {
            let badComma = new RegExp(',', 'g'); // use RegExp to remove comma and replace with line break below
            medrecPatientsArray.forEach( (patient, index) => {
                doc.addPage(); // adding a rule that MedRec patients get their own pages
                let medRecSinglePatientTable = ['', patient.pdfDisplayIndex, patient.age + " years", patient.sex === '2' ? 'F' : 'M', patient.weight + " " +(patient.weight_unit === '1' ? ' kg' : ' lbs'), 
                patient.height + " " + (patient.height_unit === '1' ? " cm" : ' in'), (patient.allergies === "" ? 'No Known Drug Allergies' : patient.allergies), patient.lab_values_last_2_weeks, patient.current_problems_icd_10, 
                patient.discharge_diagnosis_icd10, patient.discharge_date];
        
                let singlePatientMedRecOrders = medRecOrders.filter(order => order.record_id === patient.record_id);
                    // create new medRec sheet
                    doc.text(medRecTitle, 40, 25);
                    // change font size
                    doc.setFontSize(10);
                    // Add Med Rec Header and Table
                    doc.autoTable({
                        headStyles: {fillColor: 'DarkCyan'},
                        head: [['Local ID', 'Pt.', 'Age', 'Sex', 'Weight', 'Height', 'Allergies', 'Lab Values(within the last 2 weeks)', 'Current Problems(ICD 10 Code)', 
                        'Recent Hospital Discharge Diagnosis (ICD 10 Code)', 'Discharge Date']],
                        body: [medRecSinglePatientTable],
                        // below keeps the next row from being split between two pages
                        rowPageBreak: 'avoid'
                    })
                let SinglePatientPriorOrders = singlePatientMedRecOrders.filter(order => order.order_category_name === '15')
                let SinglePatientDischargeOrders = singlePatientMedRecOrders.filter(order => order.order_category_name === '16')
                    doc.text(`Medication Lists for Medication Reconciliation Patient ${patient.pdfDisplayIndex} :`, 40, doc.autoTable.previous.finalY + 15);
                    doc.autoTable({
                        headStyles: {fillColor: '#009999'},
                        columnStyles: {0: {cellWidth: 421}},
                        head: [['Last Ambulatory Med List Prior To Hospitalization', 'Recent Hospital Discharge Medication List']],
                        body: [[ // convert the result to a string and replace resulting commas to line breaks
                            (createOrdersList(SinglePatientPriorOrders)).toString().replace(badComma, '\n'), 
                            (createOrdersList(SinglePatientDischargeOrders)).toString().replace(badComma, '\n') 
                        ]],
                        // below keeps the next row from being split between two pages
                        rowPageBreak: 'avoid'
                    })
            })
        }   

        addPageNumbers(doc)
        doc.save(`EHR_Patients_Data_${date}.pdf`);
    }

    const handleAdvance = () => {
        // set current step to next step
        setCurrentStep('/enterpatients');
        // redirect to next step
        history.push('/enterpatients');
    }

    const handleCreatePDF = () => {
        createPDF(dateString);
    }

    const buttonStyle = {
        marginTop: '1em',
        marginRight: 0,
        fontWeight: '300'
    }  

    return (
        <Container className="mt-3 mb-5 pt-3 pb-3"
            style={{fontWeight: "300"}}>
            <Row>
                <Col sm={12}>
                    <h1>Step 1 of 7: Print Test Patients</h1>
                    <hr />
                    <Reminder />
                    <p className="mt-2">
                        Click "Download and Print Test Patients PDF” to download the Test Patient information. Once you confirm that the PDF has downloaded, click “Proceed to Step 2.”
                    </p>
                    <RedText />
                    <div style={{textAlign: 'right'}}>
                    {loaded && (patientsArray.length > 0) ?
                            <Button
                            style={buttonStyle}
                            className="text-nowrap px-3"
                            onClick={handleCreatePDF}
                            variant="primary"
                            >
                            Download and Print Test Patients PDF
                            </Button>
                       :
                            <Button 
                            disabled
                            style={buttonStyle} 
                            className="px-3" 
                            variant="primary"
                            >
                            Awaiting Test Patient Data
                            </Button>
                    }
                            <Button
                            style={buttonStyle}
                            onClick={handleAdvance}
                            className="px-3 ml-2"
                            variant="info"
                            >
                            Proceed to Step 2
                            </Button>
                    </div>
                </Col>
        </Row>
      </Container>
    );
}


export default PatientsPDF