import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const GetStartedVA = () => {
  return (
    <Row>
      <Col>
        <h2 id="getStarted">Get Started</h2>
        <p>
          Welcome to the EHR Safety Evaluation Tool. Whether you are taking a test for the first time or have taken a test before, here is some important information to help you get started.          
        </p>
        <h3>Information about the EHR Safety Evaluation Tool Web Page</h3>
        <p>
          The information on this web page is organized into how-to pages. Please explore the EHR Safety Evaluation Tool web page for a few minutes.
        </p>
        <p>
          Approved test(s) are listed below. When ready, please click the button, and a test code will be sent to your designated facility administrator. The email will redirect you to begin the test by logging into the test web portal with a unique access code and email address.
        </p>
        <h5>Approved tests</h5>
        <ul>
          <li>List of approved tests will go here.</li>
        </ul>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <p>Don't have an access code? Go here to <a href="#gettestcode">Get a Test Code</a>.</p>
      </Col>
    </Row>
  );
};

export default GetStartedVA;
