import React, { useContext } from 'react';
import Alert from 'react-bootstrap/Alert';
import { UserContext } from '../../context/UserContextProvider';

function decodeHtmlEntities(text) {
    const entities = {
        '&quot;': '"',
        '&#39;': "'",
        '&lt;': '<',
        '&gt;': '>',
        '&amp;': '&',
    };

    return text.replace(/&quot;|&#39;|&lt;|&gt;|&amp;/g, match => entities[match]);
}

function MOTD() {
    const { motd } = useContext(UserContext);

    if (!motd || motd.trim() === '') {
        return null; // Don't display anything if the motd is blank
    }

    try {
        // Decode the URL-encoded string
        const decodedMotd = decodeURIComponent(motd);
        // Replace HTML entities with corresponding characters
        const cleanMotd = decodeHtmlEntities(decodedMotd);
        // Parse the cleaned JSON string
        const motdData = JSON.parse(cleanMotd)[0];

        const { variant, title, text } = motdData;

        return (
            <Alert variant={variant}>
                <Alert.Heading>{title}</Alert.Heading>
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </Alert>
        );
    } catch (error) {
        console.error("Error parsing MOTD:", error);
        return null; // Don't display anything if there's an error
    }
}

export default MOTD;
