import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const GetTestCodeVA = () => {
  return (
    <Row>
      <Col>
        <h2 id="gettestcode">Get a Test Code</h2>
        <p>
          Each test has a unique security code sent to a delegated and authorized Healthcare Administrator.        
        </p>
        <p>
          If you have received a direct invitation letter with instructions, please follow the information provided. Otherwise, you can request the security code for your healthcare facility by emailing the helpdesk (link). In addition, the Healthcare Administrator can request to delegate authority to a designated contact by indicating it in the email.
        </p>
        <p>
          Note: Please allow 24 hours for a response to inquiries. Regular Office Hours are Monday through Friday, excluding holidays.
        </p>
      </Col>
    </Row>
  );
};

export default GetTestCodeVA;
