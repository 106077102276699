import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../context/UserContextProvider'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Version from '../pages/tools/Version'
import { Link } from 'react-router-dom';



const Login = () => {
    const [userCode, setUserCode] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [leapfrogTempCode, setLeapfrogTempCode] = useState(null)
    const [checkingCode, setCheckingCode] = useState(false)
    const [loading, setLoading] = useState(false)

    const {getApiConfig, loginUser, setLeapFrogTest, isLeapFrogTest, setContactAndFacilityName, serverDisplayName} = useContext(UserContext);

    // check url params for LF temp code (and for hospital id or other identifiers?)
    // -> look for `leapfrog=true&tempaccesscode=sometempaccesscode`
    useEffect(() => {
        const queryString = window.location.href.substring(window.location.href.indexOf('?'));
        const urlParams = new URLSearchParams(queryString);
        const tempCode = urlParams.get('tempaccesscode')
        const leapfrog = urlParams.get('leapfrog')
        console.log('LOGIN, tempCode === ', tempCode);
        if (tempCode !== null ) {
          setLeapfrogTempCode(tempCode)
          setCheckingCode(true)
        }
        if (leapfrog) {setLeapFrogTest()}
    }, [setLeapFrogTest])

    // check/use the leapfrogTempCode to request the real code
    useEffect(() => {
      if (leapfrogTempCode !== null) {
        // console.log('Checking temp code...', leapfrogTempCode);
        fetch(getApiConfig('exchangeTemporaryAccessCode'), {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
          },
          body: JSON.stringify({
            'access-code': leapfrogTempCode
          })
        }).then((response) => response.json())
        .then((data) => {
          // error check, then setLeapFrogTest
          // set the real code in state to trigger fetch for authorization
          // console.log("Access Code ->", data['access-code'])
          let code = data['access-code']
          setUserCode(code)
          setUserEmail('leapFrogTest')
        })
        .catch((error) => {
          console.error("Error:", error);
        });         
      }
    }, [getApiConfig, leapfrogTempCode, setLeapFrogTest])

    // if isLeapFrogTest, login (LF handshake)
    useEffect(() => {
      if (isLeapFrogTest && leapfrogTempCode && userCode && userEmail) {
        console.log('...logging in')
        let currentUser = {code: userCode, email: userEmail}
        // fetch authorization/test
        fetch(getApiConfig("siteTestLogin"), {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json"
            },
            body: JSON.stringify({
                "access-code": userCode,
                "email": userEmail
            }),
          })
          .then((response) => response.json())
          .then((data) => {
            console.log('Login Data from handshake ->', data);
            if (data.response.includes('SUCCESS')) {
                loginUser(currentUser);
                setContactAndFacilityName(data)
            } else {
                setErrorMessage(`There was a problem logging you in - please re-enter your credentials.`);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            setErrorMessage(`There was a problem logging you in - please try again.`);
          });
      }
    }, [getApiConfig, isLeapFrogTest, leapfrogTempCode, loginUser, userCode, userEmail, setContactAndFacilityName])


    const handleCodeChange = e => {
        setUserCode(e.target.value);
    }
    
    const handleEmailChange = e => {
        setUserEmail(e.target.value); 
      }

    // normal manual login
    const handleSubmit = (e) => {  
        e.preventDefault();
        setLoading(true)
        let currentUser = {code: userCode, email: userEmail}
        // fetch authorization/test
        fetch(getApiConfig("siteTestLogin"), {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json"
            },
            body: JSON.stringify({
                "access-code": userCode,
                "email": userEmail
            }),
          })
          .then((response) => response.json())
          .then((data) => {
            setLoading(false)
            console.log('Login Data from regular login->', data);
            if (data.response.includes('SUCCESS')) { // setLeapFrogTest if true in response
                loginUser(currentUser); // sets 'isLoggedIn', 'userEmail', and 'accessCode'
                if (data['test-type'] === 'leapfrog') {setLeapFrogTest()} //TODO: refactor to use 'tt-type'. 'test-type' is DEPRECATED
                setContactAndFacilityName(data)  
            } else {
                setErrorMessage(`There was a problem logging you in - please re-enter your credentials.`);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            setLoading(false)
            setErrorMessage(`There was a problem logging you in - please try again.`);
          });
    }   

    
        return ( 
          <div className="m-5" style={{fontWeight: '300'}}>
            { !checkingCode ? 
            <><main className="login-form">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header bg-primary text-white">Sign in</div>
                                <div className="card-body">
                                    <Form>
                                      <div className="form-group row pt-4">
                                          <div className="col-md-8 offset-md-2">
                                              <input placeholder="Test Code" type="text" onChange={handleCodeChange} value={userCode} id="test_code" className="form-control" name="test-code" required autoFocus />
                                          </div>
                                      </div>
    
                                      <div className="form-group row">
                                          <div className="col-md-8 offset-md-2">
                                              <input placeholder="Email" type="email" onChange={handleEmailChange} value={userEmail} id="user_email" className="form-control" name="user-email" required />
                                          </div>
                                      </div>
                                      <div className="col-md-8 offset-md-2">
                                        { loading ?
                                          <Button variant="primary" disabled>
                                            <Spinner
                                              as="span"
                                              animation="grow"
                                              size="sm"
                                              role="status"
                                              aria-hidden="true"
                                            />
                                            {" "}Loading...
                                          </Button>
                                            :
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ marginRight: '10px' }}>
                                                    Need&nbsp;to&nbsp;Register? <a href="/register">Sign&nbsp;up</a>
                                                </div>

                                                <Button variant="primary" type="submit" onClick={handleSubmit}>
                                                    Sign{'\u00A0'}in
                                                </Button>
                                            </div>
                                        }
                                      </div>
                                    </Form>
                                </div>
                            </div>
                          <div style={{textAlign: 'center'}}>{errorMessage && <em>{errorMessage}</em>}</div>
                        </div>
                    </div>
                </div>
            </main>
                <div  className="col-md-10 offset-md-1" style={{marginTop: 100}}>
                    <p className='text-center'>Please use the access code for your test and a valid email to log in and take the test.</p>
                </div></>
                :                 
                <div style={{paddingTop: '40px', textAlign: 'center'}}>
                  <p>Loading... please wait...</p>
                  <Spinner animation="grow" role="status" size="lg" variant="info">
                      <span className="sr-only">Loading... please wait...</span> 
                  </Spinner>
                </div>
            }
              <Version servername={serverDisplayName}/>
          </div>
        );
      
}

export default Login