import React, {useContext, useEffect} from 'react'
import Navbar from 'react-bootstrap/esm/Navbar'
import Countdown, { zeroPad } from 'react-countdown';
import { UserContext } from '../../context/UserContextProvider';
import { useHistory } from "react-router-dom";

// This component is rendered, but with a z-index of -1 as Leapfrog requested the clock be removed
// since removing it completely would remove a chunk of our timing logic, it hides in the UI instead
const Clock = () => {

    const history = useHistory();

    const { testTimer, isLeapFrogTest, isSampleTest, setTestTimedOut } = useContext(UserContext)

    const noTimeLeft = () => {
        // this function only handles the case of the time expiring 
        console.log('Allotted test time exceeded, score invalid.');
        // should redirect user to dashboard and only display the timeoutcard
        setTestTimedOut()
        history.push("/dashboard")
    }

    // useEffect(() => {console.log('testTimer', testTimer)}, [testTimer])
    
    useEffect(() => {
        // handles if the user logs back into a test that is underway but the test has already timed out
        if (testTimer !== null && testTimer <= 0) {
            setTestTimedOut()
            history.push("/dashboard")
        }
    }, [history, setTestTimedOut, testTimer])

    const renderer = ({hours, minutes}) => {
        return <span>{zeroPad(hours)}:{zeroPad(minutes)}</span>
      }

    return (
        <>
        {testTimer !== null ?
                <Navbar.Brand className='pr-3' style={{zIndex: !isLeapFrogTest && !isSampleTest ? 1 : -1}}>
                    <small>Time remaining:{" "}          
                    <Countdown 
                        date={Date.now() + +testTimer} 
                        renderer={renderer}
                        onComplete={noTimeLeft}
                    />
            </small>
        </Navbar.Brand>
        : ''}
        </>
    )
}

export default Clock;