import { useEffect } from "react";
import debounce from "lodash.debounce";

export default function useDisableBackButton() {
    console.log("disable backbutton called")
    useEffect(() => {
        const handlePopState = debounce((event) => {
            window.history.pushState(null, document.title, window.location.href);
        }, 500); // 500ms debounce time

        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);
}
