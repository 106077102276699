export default function PatientBoxLF2023(doc, o, offset) {

    const handleDrugNames = (n, doc, offset) => {
        // if length is over a hundred and includes a pipe, knock the font size down, check the lengths of both strings
        // if either part is over 96 characters long, set font to 9 
        // if name doesn't include pipe, leave font size as is and split onto two lines
        if (n.length > 100 && n.includes('|')) {
            doc.setFontSize(10) // knock the font size down
            let nameArr = n.split('|')
            let firstPart = nameArr[0].trim()
            let secondPart = nameArr[1].trim()
            // If either part is longer than 96 characters, reduce font size
            if (firstPart.length > 96) {doc.setFontSize(9)}
            doc.text(firstPart, 143, (offset + 46));
            if (secondPart.length > 96) {doc.setFontSize(9)}
            doc.text(secondPart, 143, (offset + 60));
            doc.setFontSize(12)
        } else if (n.includes('|')) {
            let drugArr = n.split("|")
            let first = drugArr[0].trim()
            let second = drugArr[1].trim()
            doc.text(first, 145, (offset + 45));
            doc.text(second, 145, (offset + 60));
        } else if (n.length > 72) {
            let sub = n.substring(60)
            let firstSpace = sub.indexOf(' ')
            doc.text(n.substring(0,(60+firstSpace)), 145, (offset + 45));
            doc.text(n.substring(61+firstSpace), 145, (offset + 60));
        } else {
            doc.text(n, 145, (offset + 48));
        }
    }

    let title1 = `For each of the Test Orders below, record advice/information received during or after the licensed`;
    let title2 = `prescriber has entered the Test Order with the specified dose, route and frequency for the Test Patient.`;
    // Set page title conditionally based on offset size
        if (offset < 200) {
            doc.setFontSize(12);
            doc.text(title1, 20, 25);
            doc.text(title2, 20, 39);
        }
    //  Outer box for each order
      doc.rect(20, (offset + 32), 554, 312, 'S'); // original height == 280
      doc.setFontSize(11);
      // doc.text(`Pt # ${o.record_id}`, 28, (offset + 46)); This is the previous PDF structure, using the record_id instead of an artificial index
      doc.setFont('Helvetica', 'Bold');
      if (o.display_new_patient === true) {
        // if this order is for a different patient, change color
        doc.setTextColor("blue");
        doc.text(`Pt # ${o.patient_id_test} - (Next)`, 28, (offset + 46))
        doc.setTextColor("black");
      } else {
          doc.text(`Pt # ${o.patient_id_test}`, 28, (offset + 46))
        }
      doc.text(`Order # ${o.order_id_test}`, 28, (offset + 60));
      // line(x1[from left edge], y1[from top], x2, y2, Style['S', or 'F'])
      // vertical line
      doc.line(135, (offset + 32), 135, (offset + 65));
      doc.setFont('Helvetica', '');
      doc.setFontSize(12);
      // TODO: logic to handle if string is longer than 80 characters, split onto two lines
      handleDrugNames(`${o.order_set_desc}`, doc, offset)  
      doc.line(20, (offset + 65), 574, (offset + 65));
      //   doc.setFont('Helvetica', '');
      // adding checkbox -- .rect(x[from left edge], y[from top], width, height, style['S' is default - stroke; 'F' - fill])
      // Notes textbox lives here -- each offset value below needs 32 pts added 
      // NOTES Textbox
      doc.setFontSize(11);
      doc.text(`Notes: `, 28, (offset + 76))
      doc.setFontSize(12);
      // vertical line
      doc.line(135, (offset + 65), 135, (offset + 80));
      // doc.setFontSize(10); // has no effect, registered bug 
      var textField = new doc.AcroFormTextField();
      textField.maxFontSize = 8; // has no effect - tried JsPDF versions 1.5.3, 2.1.1, and 2.2.0
      textField.Rect = [136, (offset + 65), 438, 16];
      textField.multiline = true;
      textField.fieldName = `Order_${o.order_id_test}`;
      doc.addField(textField);
      doc.setFont('Helvetica', 'Bold');
      doc.setFontSize(11);
      doc.line(20, (offset + 80), 574, (offset + 80));
      // Section A
      doc.rect(28, (offset + 32 + 53), 10, 10); // these rectangles live underneath the checkboxes so something will appear when user prints pdf
      doc.text(`(A) Medication not electronically orderable in any formulation`, 43, (offset + 32 + 61));
      doc.line(20, (offset + 32 + 68), 574, (offset + 32 + 68));
      // Section B
      doc.rect(28, (offset + 32 + 73), 10, 10);  // these rectangles live underneath the checkboxes so something will appear when user prints pdf
      doc.text(`(B) Could not enter order with specified: `, 43, (offset + 32 + 81));
      // 13 pts is good spacing between checkbox and text -- 16 pts is good spacing for one line to the next line
      doc.rect(267, (offset + 32 + 73), 10, 10);   // these rectangles live underneath the checkboxes so something will appear when user prints pdf
      doc.text(`Dose`, 280, (offset + 32 + 81));
      doc.rect(327, (offset + 32 + 73), 10, 10);   // these rectangles live underneath the checkboxes so something will appear when user prints pdf
      doc.text(`Route`, 340, (offset + 32 + 81));
      doc.rect(387, (offset + 32 + 73), 10, 10);   // these rectangles live underneath the checkboxes so something will appear when user prints pdf
      doc.text(`Frequency`, 400, (offset + 32 + 81));
      doc.line(20, (offset + 32 + 88), 574, (offset + 32 + 88));
      // Section C       TODO: underline hyphenated phrases
      doc.rect(28, (offset + 32 + 92), 10, 10); // these rectangles live underneath the checkboxes so something will appear when user prints pdf
      doc.text(`(C) Received scenario-specific advice/information concerning: `, 43, (offset + 32 + 100));
      // underline text: 'scenario-specific'
      doc.line(113, (offset + 32 + 102), 202, (offset + 32 + 102));
      doc.line(113, (offset + 32 + 102), 202, (offset + 32 + 102));
      doc.line(20, (offset + 32 + 106), 574, (offset + 32 + 106)); //horizontal line above Excessive Dosing
      // vertical line
      doc.line(135, (offset + 32 + 106), 135, (offset + 32 + 187));
      doc.text(`Excessive Dosing`, 25, (offset + 32 + 118));
      doc.setFontSize(10);
      doc.rect(140, (offset + 32 + 109), 10, 10);  // these rectangles live underneath the checkboxes so something will appear when user prints pdf
      // unbold
      doc.setFont('Helvetica', '');
      doc.text(`Suggested dose/dose calculator`, 153, (offset + 32 + 118));
      doc.rect(320, (offset + 32 + 109), 10, 10);  // these rectangles live underneath the checkboxes so something will appear when user prints pdf
      doc.text(`Excessive dose`, 333, (offset + 32 + 118));
      // doc.rect(420, (offset + 32 + 109), 10, 10);  // these rectangles live underneath the checkboxes so something will appear when user prints pdf
      // doc.text(`Dosing Advice`, 433, (offset + 32 + 118));
      doc.line(20, (offset + 32 + 122), 574, (offset + 32 + 122)); //horizontal line under Excessive Dosing, above Route
      // doc.line(20, (offset + 32 + 138), 574, (offset + 32 + 138));
      // adding 'Route'
      doc.setFont('Helvetica', 'Bold');
      doc.setFontSize(11);
      doc.text(`Route`, 25, (offset + 32 + 134));
      doc.setFontSize(10);
      doc.setFont('Helvetica', '');
      doc.rect(140, (offset + 32 + 125), 10, 10);  // these rectangles live underneath the checkboxes so something will appear when user prints pdf
      doc.text(`Route of administration`, 153, (offset + 32 + 134));
      // doc.rect(320, (offset + 32 + 125), 10, 10);  // these rectangles live underneath the checkboxes so something will appear when user prints pdf
      // doc.text(`Dosing Advice`, 333, (offset + 32 + 134));
      // doc.rect(420, (offset + 32 + 125), 10, 10);  // these rectangles live underneath the checkboxes so something will appear when user prints pdf
      // doc.text(`Excessive Daily Dose`, 433, (offset + 32 + 134));
      // doc.setFont('Helvetica', 'Bold');
      // doc.setFontSize(11);
      // doc.text(`Route`, 25, (offset + 32 + 150));
      // doc.setFontSize(10);
      // doc.setFont('Helvetica', '');
      // doc.rect(140, (offset + 32 + 141), 10, 10);  // these rectangles live underneath the checkboxes so something will appear when user prints pdf
      // doc.text(`Route of administration`, 153, (offset + 32 + 150));
      doc.line(20, (offset + 32 + 154), 574, (offset + 32 + 154));
      doc.setFont('Helvetica', 'Bold');
      doc.setFontSize(11);
      doc.text(`Labs`, 25, (offset + 32 + 166));
      doc.setFontSize(10);
      doc.setFont('Helvetica', '');
      doc.rect(140, (offset + 32 + 157), 10, 10);  // these rectangles live underneath the checkboxes so something will appear when user prints pdf
      doc.text(`Notification/display of relevant lab results`, 153, (offset + 32 + 166));
      doc.rect(350, (offset + 32 + 157), 10, 10);  // these rectangles live underneath the checkboxes so something will appear when user prints pdf
      doc.text(`Renal dosing advice`, 363, (offset + 32 + 166));
      doc.rect(140, (offset + 32 + 173), 10, 10);  // these rectangles live underneath the checkboxes so something will appear when user prints pdf
      doc.text(`Medication or dose inappropriate/contraindicated based on lab results`, 153, (offset + 32 + 181));
      // this method works differently than in a basic CRA without strictmode - in a CRA it's setLineDashPattern();
      // doc.setLineDash([1,1], 0);
      doc.line(20, (offset + 32 + 187), 574, (offset + 32 + 187));
      // vertical line
      doc.line(135, (offset + 32 + 187), 135, (offset + 32 + 238));
      // doc.setLineDash([], 0);
      doc.setFont('Helvetica', 'Bold');
      doc.setFontSize(11);
      doc.text(`Inappropriate/`, 25, (offset + 32 + 200));
      doc.setFont('Helvetica', '');
      doc.setFontSize(10);
      doc.rect(140, (offset + 32 + 191), 10, 10);  // these rectangles live underneath the checkboxes so something will appear when user prints pdf 
      doc.text(`Age (including inappropriate dose due to age)`, 153, (offset + 32 + 200));
      doc.setFont('Helvetica', 'Bold');
      doc.setFontSize(11);
      doc.text(`Contraindication`, 25, (offset + 32 + 216));
      doc.setFont('Helvetica', '');
      doc.setFontSize(10);
      doc.rect(140, (offset + 32 + 207), 10, 10);  // these rectangles live underneath the checkboxes so something will appear when user prints pdf 
      doc.text(`Diagnosis (including inappropriate dose due to diagnosis)`, 153, (offset + 32 + 216));
      // doc.text(`Allergy to medication`, 153, (offset + 32 + 232));
      doc.setFont('Helvetica', 'Bold');
      doc.setFontSize(11);
      doc.text(`based on:`, 25, (offset + 32 + 232));
      doc.setFont('Helvetica', '');
      doc.setFontSize(10);
      // doc.rect(140, (offset + 32 + 223), 10, 10);  // these rectangles live underneath the checkboxes so something will appear when user prints pdf 
      // doc.text(`Diagnosis (including inappropriate dose due to diagnosis)`, 153, (offset + 32 + 232));
      doc.line(20, (offset + 32 + 238), 574, (offset + 32 + 238));
      // Section D
      doc.rect(28, (offset + 32 + 242), 10, 10); // these rectangles live underneath the checkboxes so something will appear when user prints pdf
      doc.setFont('Helvetica', 'Bold');
      doc.setFontSize(11);
      doc.text(`(D) Received medication-specific advice/information concerning: `, 43, (offset + 32 + 250));
      // underline text: 'medication-specific'
      doc.line(113, (offset + 32 + 252), 215, (offset + 32 + 252));
      doc.line(113, (offset + 32 + 252), 215, (offset + 32 + 252));
      doc.line(20, (offset + 32 + 256), 574, (offset + 32 + 256));
      // vertical line
      doc.line(135, (offset + 32 + 257), 135, (offset + 32 + 291));
      doc.text(`Monitoring: `, 25, (offset + 32 + 268));
      doc.setFont('Helvetica', '');
      doc.setFontSize(10);
      doc.rect(140, (offset + 32 + 259), 10, 10);  // these rectangles live underneath the checkboxes so something will appear when user prints pdf
      doc.text(`Need to monitor via new lab order(s)`, 153, (offset + 32 + 268));
      doc.rect(325, (offset + 32 + 259), 10, 10);  // these rectangles live underneath the checkboxes so something will appear when user prints pdf
      doc.text(`Monitoring order for a new lab was provided`, 338, (offset + 32 + 268));
      doc.line(20, (offset + 32 + 273), 574, (offset + 32 + 273));
      doc.setFont('Helvetica', 'Bold');
      doc.setFontSize(9);
      doc.text(`Medication Combination: `, 25, (offset + 32 + 285));
      doc.setFont('Helvetica', '');
      doc.setFontSize(10);
      doc.rect(140, (offset + 32 + 276), 10, 10);  // these rectangles live underneath the checkboxes so something will appear when user prints pdf
      doc.text(`Inappropriate medication combination (e.g., drug-drug interaction or therapeutic duplication)`, 153, (offset + 32 + 285));
      doc.line(20, (offset + 32 + 291), 574, (offset + 32 + 291));
      // Section E
      doc.rect(28, (offset + 32 + 296), 10, 10); // these rectangles live underneath the checkboxes so something will appear when user prints pdf
      doc.setFont('Helvetica', 'Bold');
      doc.setFontSize(11);
      doc.text(`(E) Order entered and signed, no advice/information received`, 43, (offset + 32 + 305));
      doc.setFont('Helvetica', '');
      doc.setFontSize(10);
      doc.setFont('Helvetica', 'Bold'); // CPOE requested this to all be bold
      doc.text(`If able to enter the order: sign order, finish observations, then discontinue order(s) before proceeding to the next.`, 20, (offset + 32 + 325));
      doc.setFont('Helvetica', '');


      // RADIO BUTTON GROUPS 
      // MAIN Group that translates to response form 

      var radioGroup = new doc.AcroFormRadioButton();
      radioGroup.value = "Main";
      // radioGroup.Subtype = "Form";

      doc.addField(radioGroup);

      var radioButton1 = radioGroup.createOption("Main1");
      radioButton1.Rect = [28, (offset + 85), 10, 10];
      radioButton1.appearanceState = 'Off';

      var radioButton2 = radioGroup.createOption("Main2");
      radioButton2.Rect = [28, (offset + 105), 10, 10];
      radioButton2.appearanceState = 'Off';

      var radioButton3 = radioGroup.createOption("Main3");
      radioButton3.Rect = [28, (offset + 124), 10, 10];
      radioButton3.appearanceState = 'Off';

      var radioButton4 = radioGroup.createOption("Main4");
      radioButton4.Rect = [28, (offset + 274), 10, 10];
      radioButton4.appearanceState = 'Off';

      var radioButton5 = radioGroup.createOption("Main5");
      radioButton5.Rect = [28, (offset + 328), 10, 10];
      radioButton5.appearanceState = 'Off';

      radioGroup.setAppearance(doc.AcroFormAppearance.RadioButton.Cross);

      // SUBGROUP 
      var radioSubGroup = new doc.AcroFormRadioButton();
      radioSubGroup.value = "Secondary";

      doc.addField(radioSubGroup);
      // Group for section (B)
      var radioSubButton1 = radioSubGroup.createOption("Sub1");
      radioSubButton1.Rect = [267, (offset + 105), 10, 10];
      radioSubButton1.appearanceState = 'Off';

      var radioSubButton2 = radioSubGroup.createOption("Sub2");
      radioSubButton2.Rect = [327, (offset + 105), 10, 10];
      radioSubButton2.appearanceState = 'Off';

      var radioSubButton3 = radioSubGroup.createOption("Sub3");
      radioSubButton3.Rect = [387, (offset + 105), 10, 10];
      radioSubButton3.appearanceState = 'Off';

      // Group for section (C) row 1 'Excessive Dosing'
      var radioSubButton4 = radioSubGroup.createOption("Sub4");
      radioSubButton4.Rect = [140, (offset + 141), 10, 10];
      radioSubButton4.appearanceState = 'Off';

      var radioSubButton5 = radioSubGroup.createOption("Sub5");
      radioSubButton5.Rect = [320, (offset + 141), 10, 10];
      radioSubButton5.appearanceState = 'Off';

      // var radioSubButton6 = radioSubGroup.createOption("Sub6");
      // radioSubButton6.Rect = [420, (offset + 141), 10, 10];
      // radioSubButton6.appearanceState = 'Off';
      
      // Group for section (C) row 2  'Daily Dose'
      var radioSubButton7 = radioSubGroup.createOption("Sub7");
      radioSubButton7.Rect = [140, (offset + 157), 10, 10];
      radioSubButton7.appearanceState = 'Off';
      
      // var radioSubButton8 = radioSubGroup.createOption("Sub8");
      // radioSubButton8.Rect = [320, (offset + 157), 10, 10];
      // radioSubButton8.appearanceState = 'Off';
      
      // var radioSubButton9 = radioSubGroup.createOption("Sub9");
      // radioSubButton9.Rect = [420, (offset + 157), 10, 10];
      // radioSubButton9.appearanceState = 'Off';
      
      // Group for section (C) row 3 (NEW) 'Route' 173
      // var radioSubButton19 = radioSubGroup.createOption("Sub19");
      // radioSubButton19.Rect = [140, (offset + 173), 10, 10];
      // radioSubButton19.appearanceState = 'Off';

      // Group for section (C) row 4 'Labs'
      var radioSubButton10 = radioSubGroup.createOption("Sub10");
      radioSubButton10.Rect = [140, (offset + 189), 10, 10];
      radioSubButton10.appearanceState = 'Off';
      
      var radioSubButton11 = radioSubGroup.createOption("Sub11");
      radioSubButton11.Rect = [350, (offset + 189), 10, 10];
      radioSubButton11.appearanceState = 'Off';
      
      var radioSubButton12 = radioSubGroup.createOption("Sub12");
      radioSubButton12.Rect = [140, (offset + 205), 10, 10];
      radioSubButton12.appearanceState = 'Off';


      // Group for section (C) row 5 'Inappropriate' 191, 207, 223
      var radioSubButton13 = radioSubGroup.createOption("Sub13");
      radioSubButton13.Rect = [140, (offset + 223), 10, 10];
      radioSubButton13.appearanceState = 'Off';
      
      var radioSubButton14 = radioSubGroup.createOption("Sub14");
      radioSubButton14.Rect = [140, (offset + 239), 10, 10];
      radioSubButton14.appearanceState = 'Off';
      
      // var radioSubButton15 = radioSubGroup.createOption("Sub15");
      // radioSubButton15.Rect = [140, (offset + 255), 10, 10];
      // radioSubButton15.appearanceState = 'Off';

      // Group for section (D) 'Received Advice'
      var radioSubButton16 = radioSubGroup.createOption("Sub16");
      radioSubButton16.Rect = [140, (offset + 291), 10, 10];
      radioSubButton16.appearanceState = 'Off';
      
      var radioSubButton17 = radioSubGroup.createOption("Sub17");
      radioSubButton17.Rect = [325, (offset + 291), 10, 10];
      radioSubButton17.appearanceState = 'Off';
      
      var radioSubButton18 = radioSubGroup.createOption("Sub18");
      radioSubButton18.Rect = [140, (offset + 308), 10, 10];
      radioSubButton18.appearanceState = 'Off';

      // SET APPEARANCE of subgroup - set appearance must be called last
      radioSubGroup.setAppearance(doc.AcroFormAppearance.RadioButton.Cross);

  }