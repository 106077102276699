import React, {useContext} from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { UserContext } from '../../context/UserContextProvider';
// import { useHistory } from 'react-router';


const TimeOutModal = () => {

const {showTimeOutModal, logoutUser, ttGroup} = useContext(UserContext);

const handleCloseModal = () => {
    // log user out of tool - redirect to Leapfrog if isLeapFrogTest ?
    logoutUser()
    window.location.replace("https://awsupgrade.survey.leapfroggroup.org/dashboard")
}

const helpdeskLink = 'https://leapfroghelpdesk.zendesk.com/hc/en-us'

    if (ttGroup === "LF") {
      return (
        <Modal size='xl' show={showTimeOutModal} animation={false} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Timed out of Test (Publicly reported as Incomplete Evaluation):</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><strong>This hospital did not complete the CPOE Evaluation Tool within the allotted time. The hospital is eligible to retake the test in 120 days. Please contact the <a href={helpdeskLink}>Leapfrog Help Desk</a> for further assistance.</strong></p>
            <p>Click 'OK' to exit the test.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseModal}> 
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      )
    }

    return(
      <Modal size='xl' show={showTimeOutModal} animation={false} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Timed out of Test (Publicly reported as Incomplete Evaluation):</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>This hospital did not complete the CPOE Evaluation Tool within the allotted time. The hospital is eligible to retake the test in 120 days. Please contact <a href="mailto: Helpdesk_EHRSafetyEvalTool@utah.edu">Helpdesk_EHRSafetyEvalTool@utah.edu</a> for further assistance.</strong></p>
          <p>Click 'OK' to exit the test.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}> 
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default TimeOutModal