import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const GetHelp = () => {
  return (
    <Row>
      <Col>
        <h2 id="getHelp">Get Help</h2>
        <h3>General Help Desk Information</h3>
        <p>
          If you have any questions regarding the EHR Safety Evaluation Tool, need general assistance, or would like to submit feedback, please contact <a href="mailto:Helpdesk_EHRSafetyEvalTool@utah.edu">Helpdesk_EHRSafetyEvalTool@utah.edu</a>.
        </p>
        <h3>Get the answers you need</h3>
        <p>
          Out team members can help.
        </p>
        <p>
          Contact the Help Desk: <a href="mailto:Helpdesk_EHRSafetyEvalTool@utah.edu">Helpdesk_EHRSafetyEvalTool@utah.edu</a>
        </p>
      </Col>
    </Row>
  );
};

export default GetHelp;
