import React from 'react';
import Container from "react-bootstrap/Container";

const Footer = () => {
    
    
    return (
        <Container className="mt-3 mb-0 pt-3">
            <hr />
            <p style={{fontSize: 'x-small', paddingBottom: '20px'}}>
            Hospitals do not obtain any rights in the CPOE Evaluation Tool ("Tool") by virtue of conducting, administering, or participating in the Leapfrog Hospital Survey ("Survey"). Hospitals are permitted to use the Tool solely for purposes of such Survey participation and only within their organization. Hospitals must obtain explicit written permission from The Brigham and Women's Hospital, Inc., The University of Utah, and The Leapfrog Group to use the Tool for any other purpose or to share the Tool with other entities or with persons other than their direct employees involved in the Survey. For clarity, the Tool includes software and content, including but not limited to patient descriptions and medication order scenarios, and the restrictions and requirements stated above apply to the entire Tool and any of its components.
            </p>
        </Container>
    )
}

export default Footer