import { useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContextProvider";

export default function useSetCurrentStep(step, stepNum) {

    const {accessCode, getApiConfig, setStepNumber, currentTestStepNumber, isSampleTest} = useContext(UserContext)

    useEffect(() => {
        // sets current step, if successful sets step for UI/Context
        if (currentTestStepNumber !== stepNum && !isSampleTest) {
            fetch(getApiConfig("updateCurrentSiteTestStep"), {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify({
                    "access-code": accessCode,
                    "ui-step": step
                })})
                .then((response) => response.json())
                .then((data) => {
                // console.log('update test step response data = ', data);
                // console.log('post current step data.response = ', data.response);
                if (data.response.includes('SUCCESS') ) {
                  // setting step number will check and set time for test clock  
                  setStepNumber(stepNum)
                    console.log('setting context step # to ', stepNum);
                }
                })
                .catch((error) => {
                console.error("Error:", error);
                });
        }
          
      }, [accessCode, getApiConfig, step, currentTestStepNumber, stepNum, setStepNumber, isSampleTest])
}