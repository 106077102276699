import React, {useContext, useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import GetStartedVA from "./tools/GetStartedVA";
import InstructionsVA from "./tools/InstructionsVA";
import ToolkitFeedbackVA from "./tools/ToolKitFeedBackVA";
import GetHelp from "./tools/GetHelp";
import GetTestCodeVA from "./tools/GetTestCodeVA";
import { UserContext } from '../context/UserContextProvider';



const HelpWiki = () => {
  const {isLeapFrogTest} = useContext(UserContext);

  return (
      <Container className="mt-5" style={{ fontWeight: '300' }}>
        <Row>
          <Col sm={3}>
            <h2>Table of Contents</h2>
            <ol>
              <li><a href="#getStarted">Get Started</a></li>
              <li><a href="#instructions">Instructions</a></li>
              <li><a href="#gettestcode">Get a Test Code</a></li>
              {isLeapFrogTest ?
                  <li><Link to="/sampledashboard">Sample Test</Link></li>
                : ""
              }
              <li><a href="#toolkit">Toolkit and Feedback</a></li>
              <li><a href="#getHelp">Get Help</a></li>
            </ol>
          </Col>
          <Col sm={9}>
            <GetStartedVA />
          </Col>
          <Col sm={12}>
            <InstructionsVA />
            <GetTestCodeVA />
            <ToolkitFeedbackVA />
            <GetHelp />
          </Col>
        </Row>
      </Container>
    );
  
}

export default HelpWiki;
