var _ = require('lodash');
var dayjs = require('dayjs')

const sortOrders = (orders) => {
  if (orders[0].display_sequence === undefined) { 
    return _.orderBy(orders, [
        function(order) {return Number(order.record_id)},
        function(order) {return order.redcap_repeat_instance}
        ], ['asc', 'asc']);
  } else {
    return _.orderBy(orders, [
      function(order) {return Number(order.record_id)},
      function(order) {return order.display_sequence}
      ], ['asc', 'asc']);
  }

}

// due to the patients living in an object
// use Object.entries() to iterate the patients for sorting
const sortPatients = (patients) => {
  return _.orderBy(patients, [
    function(patient) {return Number(patient.record_id)}
    ], ['asc']);
} 


// This function differs slightly from the admin client global function `sortTheResponses`
const sortResponsesForDisplay = (responses) => { 
  return _.sortBy(responses, [
    function(resp) { return parseInt(resp[1].split("-")[1]); }, // patient_id
    function(resp) { return parseInt(resp[1].split("-")[2]); } // order_id
  ]);
}



const hashTheCode = (str) => {
	var hash = 0;
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
        let char = str.charCodeAt(i);
        hash = ((hash<<5)-hash)+char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

/*
credit for the hashTheCode to https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
*/


// If this function finds the string "FAILURE" in an object's values, it will return false ("False")
// to make it return true if it finds failure, call it like so:
// !failureCheckObject(theObjectIAmChecking) 
const failureCheckObject = (obj) => {	
  const result = Object.entries(obj).filter( ([key, value]) => value.includes("FAILURE"));
  return result.length === 0
}


// TODO: sort out how to programmatically update this URL
const handleReturnToLeapFrog = (url) => {
  window.location.replace(url)
}


const dayJSPrep = () => {
    var utc = require('dayjs/plugin/utc')
    var timezone = require('dayjs/plugin/timezone')
    var customParseFormat = require('dayjs/plugin/customParseFormat')
    dayjs.extend(utc)
    dayjs.extend(customParseFormat)
    dayjs.extend(timezone)
}


const convertTimeLocal = (timeStr) => {
    dayJSPrep()
    let local = dayjs(timeStr).utc('z').local().format('MM/D/YYYY h:mm:ss A')
    return local
}

export {convertTimeLocal, sortOrders, sortPatients, sortResponsesForDisplay, hashTheCode, failureCheckObject, handleReturnToLeapFrog};
