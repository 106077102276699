import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ToolkitFeedbackVA = () => {
  return (
    <Row>
      <Col>
        <h2 id="toolkit">Toolkit and Feedback</h2>
        <h5>Guidance Toolkit</h5>
        <p>
          <em>What is it?</em>
        </p>
        <p>
          The Guidance Toolkit is a best practices document to help improve electronic health record physician alerting. In it, you will find tips and useful feedback for you and your facility.
        </p>
        <p>
          VA Guidance Toolkit Link: [to be added later]
        </p>
        <p>Feedback can be sent directly to the EHR Safety Team at: <a href="mailto:Helpdesk_EHRSafetyEvalTool@utah.edu">Helpdesk_EHRSafetyEvalTool@utah.edu</a></p>
      </Col>
    </Row>
  );
};

export default ToolkitFeedbackVA;
