import React, {useState, useEffect, useContext} from 'react'
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import { UserContext } from "../../context/UserContextProvider";


const Reminder = () => {

    const { currentTestStepNumber, currentStep, deadline, isSampleTest, sampleTestDeadline} = useContext(UserContext);

    const [reminderSteps, setReminderSteps] = useState('')
    
    
    useEffect(() => { // fix this!
        if (isSampleTest) {
            if (currentStep === '/patientspdf' || currentStep === '/enterpatients') {
                setReminderSteps('1 - 2 and proceed to Step 3')
            } else {
                setReminderSteps('3 - 6')
            }
        } else {
            if (currentTestStepNumber === 1 || currentTestStepNumber === 2) {
                setReminderSteps('1 - 2 and proceed to Step 3')
            } else if (currentTestStepNumber > 2 && currentTestStepNumber < 7) {
                setReminderSteps('3 - 6')
            } else {
                setReminderSteps('1-6')
            }
        }
    }, [currentTestStepNumber, currentStep, isSampleTest])


    return(
            <Row className="mb-3">
                <Col sm={12}>
                    <Card>
                        <Card.Body>
                            <p className="m-0 text-left">
                            {isSampleTest ? 
                                <span style={{float: 'right'}}>You have until <strong>{sampleTestDeadline} EDT </strong>to complete Steps {reminderSteps}.</span>
                                :<span style={{float: 'right'}}>You have until <strong>{deadline} EDT </strong>
                                to complete Steps {reminderSteps}.</span>
                            }
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
    )
}

export default Reminder
