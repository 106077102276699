
    // for function, add offset value for placing elements
   export default function MedRecBox(doc, o, offset) {
    let title1 = `Using your EHR's medication reconciliation function, please record any advice or information the `;
    let title2 = `prescriber receives during or after entering each medication order.`;
    // Set page title conditionally based on offset size
        if (offset < 200) {
            doc.setFontSize(12);
            doc.text(title1, 20, 25);
            doc.text(title2, 20, 38);
        } 
    //  Outer box for each order
        doc.rect(20, (offset + 32), 554, 312, 'S');
        doc.setFontSize(11);
        doc.setFont('Helvetica', 'Bold');
        if (o.display_new_patient === true) {
            // if this order is for a different patient, change color
            doc.setTextColor("blue");
            doc.text(`Pt # ${o.patient_id_test} - (Next)`, 28, (offset + 46))
            doc.setTextColor("black");
          } else {
              doc.text(`Pt # ${o.patient_id_test}`, 28, (offset + 46))
            }
        doc.text(`Order # ${o.order_id_test}`, 28, (offset + 60));
        // line(x1[from left edge], y1[from top], x2, y2, Style['S', or 'F'])
                // TODO: logic to handle if string is longer than 80 characters, split onto two lines
                if  (o.order_set_desc.length > 72) {
                    doc.text(`${o.order_set_desc.substring(0, 71) + '-'}`, 110, (offset + 46))
                    doc.text(`${o.order_set_desc.substring(71)}`, 110, (offset + 60))
                } else {
                    doc.text(`${o.order_set_desc}`, 110, (offset + 46));
                } 
        doc.text(`Notes: `, 28, (offset + 95));
        doc.setFont('Helvetica', 'normal');
        doc.line(20, (offset + 68), 574, (offset + 68));

        // Section A
        // adding checkbox -- .rect(x[from left edge], y[from top], width, height, style['S' is default - stroke; 'F' - fill])
        doc.rect(33, (offset + 135), 12, 12);
        doc.setFontSize(14);
        // doc.setFont('Helvetica', 'Bold');
        doc.text(`(A)`, 55, (offset + 145))
        doc.setFontSize(11);
        doc.text(`The EHR system does not electronically detect changes in the medication list,`, 110, (offset + 139));
        doc.text(`instead the clinic staff reconcile the medication lists manually`, 110, (offset + 152));
        
        
        // Section B
        // 13 pts is good spacing between checkbox and text -- 16 pts is good spacing for one line to the next line
        doc.rect(33, (offset + 184), 12, 12);
        doc.setFontSize(14);
        doc.text(`(B)`, 55, (offset + 194))
        doc.setFontSize(11);
        doc.text(`Received advice/information concerning a medication that is`, 110, (offset + 188));
        doc.setFont('Helvetica', 'Bold');
        doc.text(`no longer on the`, 405, (offset + 188));
        doc.text(`most recent discharge medication list `, 110, (offset + 201));
        doc.setFont('Helvetica', 'normal');
        
        // C
        doc.rect(33, (offset + 231), 12, 12);
        doc.setFontSize(14);
        doc.text(`(C)`, 55, (offset + 241))
        doc.setFontSize(11);
        doc.text(`Received advice/information concerning a`, 110, (offset + 235));
        doc.setFont('Helvetica', 'Bold');
        doc.text(`new medication that was added`, 317, (offset + 235));
        doc.setFont('Helvetica', 'normal');
        doc.text(`to the`, 484, (offset + 235));
        doc.text(`most recent discharge medication list`, 110, (offset + 248));
        
        // Vertical lines
        doc.line(100, (offset + 32), 100, (offset + 344));
        // Horizontal lines
        doc.line(20, (offset + 115), 574, (offset + 115)); // top of box A
        doc.line(20, (offset + 168), 574, (offset + 168)); // top of box B
        doc.line(20, (offset + 216), 574, (offset + 216)); // top of box C
        doc.line(20, (offset + 260), 574, (offset + 260)); // top of box D
        doc.line(20, (offset + 306), 574, (offset + 306)); // top of box E
        
        // D
        doc.rect(33, (offset + 277), 12, 12);
        doc.setFontSize(14);
        doc.text(`(D)`, 55, (offset + 287));
        doc.setFontSize(11);
        doc.text(`Received advice/information concerning a `, 110, (offset + 281));
        doc.setFont('Helvetica', 'Bold');
        doc.text(`change in the dose`, 318, (offset + 281));
        doc.setFont('Helvetica', 'normal');
        doc.text(` of an existing`, 418, (offset + 281));
        doc.text(`medication that was on the pre-existing ambulatory clinic medication list`, 110, (offset + 294));
        
        // E
        
        doc.rect(33, (offset + 320), 12, 12);
        doc.setFontSize(12);
        doc.text(`(E)`, 55, (offset + 330));
        doc.setFontSize(11);
        doc.text(`No advice/information received about changes to the medication list`, 110, (offset + 330));
        doc.setFont('Helvetica', 'normal'); // sets font back from bold to normal

        // Add Notes box
        var textField = new doc.AcroFormTextField();
        textField.maxFontSize = 10;
        textField.Rect = [100, (offset + 68), 474, 47];
        textField.multiline = true;
        textField.fieldName = `Order_${o.order_id_test}`;
        doc.addField(textField);


      // RADIO BUTTON GROUP
      var radioGroup = new doc.AcroFormRadioButton();
      radioGroup.value = "MedRec";
      // radioGroup.Subtype = "Form";

      doc.addField(radioGroup);

      var radioButton1 = radioGroup.createOption("MedRec1");
      radioButton1.Rect = [33, (offset + 135), 12, 12];
      radioButton1.appearanceState = 'Off';

      var radioButton2 = radioGroup.createOption("MedRec2");
      radioButton2.Rect = [33, (offset + 184), 12, 12];
      radioButton2.appearanceState = 'Off';

      var radioButton3 = radioGroup.createOption("MedRec3");
      radioButton3.Rect = [33, (offset + 231), 12, 12];
      radioButton3.appearanceState = 'Off';

      var radioButton4 = radioGroup.createOption("MedRec4");
      radioButton4.Rect = [33, (offset + 277), 12, 12];
      radioButton4.appearanceState = 'Off';

      var radioButton5 = radioGroup.createOption("MedRec5");
      radioButton5.Rect = [33, (offset + 320), 12, 12];
      radioButton5.appearanceState = 'Off';

      radioGroup.setAppearance(doc.AcroFormAppearance.RadioButton.Cross);
    }

