import React, { useEffect, useContext } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { UserContext } from "../context/UserContextProvider";
import HelpWiki from "./HelpWiki";

const InstructionsPDF = () => {

  const { ttGroup } = useContext(UserContext)

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event){
        window.history.pushState(null, document.title,  window.location.href);
    });
  }, [])

  if (ttGroup !== "LF") {
    return (
      <HelpWiki />
    )
  }

  return (
    <Container className="mt-5" style={{ fontWeight: '300' }}>
      <Card>
        <Card.Header className="font-weight-bold">
          Instructions
        </Card.Header>
        <Card.Body>Download the CPOE Tool Instructions from the <a href="https://www.leapfroggroup.org/survey-materials/survey-and-cpoe-materials" target="_blank" rel="noreferrer">Survey and CPOE Materials website</a> under Other Supporting Materials for Section 2.</Card.Body>
      </Card>
    </Container>
  );
  
}

export default InstructionsPDF;
