import React, {useContext, useState, useEffect} from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast'
import { useHistory } from "react-router-dom";
import { UserContext } from '../context/UserContextProvider';
import {sortOrders, sortResponsesForDisplay, hashTheCode, failureCheckObject} from '../globals'; // TODO refactor sortResponses into globals
import Reminder from '../pages/tools/Reminder'
import useDisableBackButton from "./hooks/useDisableBackButton";
import useSetCurrentStep from './hooks/useSetCurrentStep';

const RecordResponses = () => {
    
    const {accessCode, getApiConfig, setCurrentStep, setStepNumber} = useContext(UserContext);
    
    const storage = window.localStorage;
    //orders and responses state
    const [responses, setResponses] = useState({});
    const [responsesToScore, setResponsesToScore] = useState([]);
    const [displayResponses, setDisplayResponses] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [unsortedOrders, setUnsortedOrders] = useState([]);
    const [orders, setOrders] = useState([]);
    const [displayOrders, setDisplayOrders] = useState([]);
    // score sheet state
    const [rawScoreSheet, setRawScoreSheet] = useState({});
    const [scoreSheet, setScoreSheet] = useState({});
    // utility state
    const [loaded, setLoaded] = useState(false);
    const [transferredStorageToResponses, setTransferredStorageToResponses] = useState(false);
    const [hashedCode, setHashedCode] = useState('');
    const [noScoringAssigned, setNoScoringAssigned] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const failureMessage = `There was a problem recording your responses. Please try again.`
    // validation/find question missing an answer index
    const [ordersMissingAnswers, setOrdersMissingAnswers] = useState([]);
    const [numMissingAnswers, setNumMissingAnswers] = useState(0)
    const [alertMissing, setAlertMissing] = useState(false)

    const history = useHistory(); 

    var _ = require('lodash'); // this can be most likely removed, but test first
    const CapitalLettersRegex = /[A-Z]/;

    useDisableBackButton()  // Disable the browser's back button 
    useSetCurrentStep("RECORD_RESPONSES", 5)

    useEffect(() => {
        const getOrders = () => {
            // hash the access code, store it in state
            setHashedCode(hashTheCode(accessCode))
            //on mount fetch 
            fetch(getApiConfig("getSiteTestOrders"), {
                method: "POST",
                mode: "cors",
                headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
                },
                body: JSON.stringify({
                    "access-code": accessCode,
                }),
            })
            .then((response) => response.json())
            .then((data) => {
                let rawData = JSON.parse(decodeURIComponent(data.data));
                // save the data in state to trigger sorting
                setUnsortedOrders(rawData[1].orders);
                // most likely will need to re-arrange orders -> regular before med rec 
                // console.log('test orders data: ', JSON.stringify(rawData));
                // console.log('test orders received ', rawData[1].orders);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
        }
        getOrders();
    }, [accessCode, getApiConfig])

    useEffect(() => {
        const getScoringSheet = () => {
            // fetch the assigned scoring sheet
            fetch(getApiConfig("getSiteTestScoreSheet"), {
                method: "POST",
                mode: "cors",
                headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
                },
                body: JSON.stringify({
                    "access-code": accessCode,
                }),
            })
            .then((response) => response.json())
            .then((data) => {
                // save the data in state to trigger sorting
                // console.log('getSiteTestScoreSheet RESPONSE: ', data)
                //TODO: normal categories and medrec categories check needs abstracting to one common JS library!!!!
                let nonMedRecRows = data.filter(o => o.order_category_id <= 14 || o.order_category_id >= 18)
                let medRecRows = data.filter(o => o.order_category_id === 17)
                let combinedRows = [...nonMedRecRows, ...medRecRows];
                // sort the scoresheet data before passing to the loop to create ScoreSheet Object lookup
                const sortedRows = combinedRows.sort((a,b) => a.order_category_id - b.order_category_id || a.order_response_id - b.order_response_id)
                // console.log('sorting scoreSheet BEFORE setting in state', sortedRows)
                setRawScoreSheet(sortedRows);
                // alert user if scoring isn't assigned ([])
            })
            .catch((error) => {
                console.error("Error:", error);
            });
        }

        getScoringSheet();
    }, [accessCode, getApiConfig])


    useEffect(() => { // CREATE SCORING OBJECT -> NOTE: For now, scoring rows must be returned from server in order, ascending 1-x
        // alert user if scoring isn't assigned ([])
        if (rawScoreSheet.length === 0) {
            setNoScoringAssigned(true)
        } else {
            // TODO: Sort the rawScoreSheet ascending by order_category_id
            let rawSheet = {};
            let incrementor = 1;
            let deceptionPrefix = '';
            let subcategorySuffix = ''
            for (let i = 0; i < rawScoreSheet.length; i++) { 
                // console.log(`Iteration ${i}: ${JSON.stringify(rawSheet)}`)
                // add a prefix to the object property if deception is true
                rawScoreSheet[i].deception ? deceptionPrefix = 'deception' : deceptionPrefix = '';
                rawScoreSheet[i].order_subcategory_id !== null ? subcategorySuffix = `-${rawScoreSheet[i].order_subcategory_id}` : subcategorySuffix = '';

                    // arrange scoreSheet rows into an object based on the category
                    if ( rawScoreSheet[i].order_category_id > incrementor 
                       && rawScoreSheet[i].order_subcategory_id !== null 
                       && rawSheet[`${deceptionPrefix+incrementor+subcategorySuffix}`] === undefined) {
                       incrementor = rawScoreSheet[i].order_category_id;
                       // if subcategory is not null and does not exist - create it and push to it
                       rawSheet[`${deceptionPrefix+incrementor+subcategorySuffix}`] = [];
                       rawSheet[`${deceptionPrefix+incrementor+subcategorySuffix}`].push(rawScoreSheet[i]);
                    } else if ( rawScoreSheet[i].order_subcategory_id !== null 
                        && rawSheet[`${deceptionPrefix+incrementor+subcategorySuffix}`] !== undefined) { // if subcategory exists push to it
                        rawSheet[`${deceptionPrefix+incrementor+subcategorySuffix}`].push(rawScoreSheet[i]);
                    } else if (rawScoreSheet[i].order_category_id === incrementor 
                        // && rawScoreSheet[i].order_subcategory_id === null
                        && rawScoreSheet[i].order_response_index === 1) {
                        rawSheet[`${deceptionPrefix+incrementor+subcategorySuffix}`] = [];
                        rawSheet[`${deceptionPrefix+incrementor+subcategorySuffix}`].push(rawScoreSheet[i]);
                    } else if (rawScoreSheet[i].order_category_id > incrementor && rawScoreSheet[i].order_response_index === 1) {
                        incrementor = rawScoreSheet[i].order_category_id;
                        rawSheet[`${deceptionPrefix+incrementor+subcategorySuffix}`] = [];
                        rawSheet[`${deceptionPrefix+incrementor+subcategorySuffix}`].push(rawScoreSheet[i]);
                    } else {
                        rawSheet[`${deceptionPrefix+incrementor+subcategorySuffix}`].push(rawScoreSheet[i]);
                    } 
                }
            // console.log('here\'s rawSheet(the Score Sheet): ', rawSheet);
            setScoreSheet(rawSheet);
        }
    }, [rawScoreSheet])


    useEffect(() => { // sort orders  //  record_id is a string, redcap_repeat_instance is a number
        if (unsortedOrders.length !== 0) {
            let sorted = sortOrders(unsortedOrders);
            // filter non-medrec orders, medrec observation orders, and leave med rec prior & discharge
            //TODO: normal categories and medrec categories check needs abstracting to one common JS library!!!!
            let sortedPatientOrders = sorted.filter(o => Number(o.order_category_name) <= 14 || o.order_category_name >= 18)
            let sortedMedRecOrders = sorted.filter(o => Number(o.order_category_name) === 17)
            let combinedOrders = [...sortedPatientOrders, ...sortedMedRecOrders]
            // method for pulling storage data and updating the UI
            let updatedOrders = [...combinedOrders];
            if (storage.length > 0) {
                Object.entries(storage).forEach( ([key, value]) => {
                    if (key.includes(hashedCode)) {
                        Object.entries(updatedOrders).forEach((order) => {
                        if (order[1].record_id === key.substring((key.indexOf("-") + 1), key.indexOf("_")) 
                            && order[1].redcap_repeat_instance.toString() === key.substring((key.indexOf("d") + 4), key.lastIndexOf("_")) )
                            {
                            // console.log('MATCH: storage key', key, 'order interation', order[1]);
                            order[1]['test_response_index'] = value.substring(0, value.indexOf("-"))
                        } 
                        })
                    }
                }) 
            }
            setOrders(combinedOrders);
        }    
    }, [unsortedOrders, storage, hashedCode])

    useEffect(() => {
        const assignPatientIndex = () => {   // sort orders and add new patient index
            let newOrdersArray = [...orders]
            // sort this array here - could cause issues for medRec orders 
            // newOrdersArray = sortOrders(newOrdersArray)
            // console.log('orders array (spread from state) ... is this in order?', newOrdersArray);
            let patientNum = parseInt(newOrdersArray[0].record_id);
            let patientIndex = 1;
            // let orderIndex = 1;
            for (let i = 0; i < newOrdersArray.length; i++) {
                if (parseInt(newOrdersArray[i].record_id) !== patientNum) {
                    patientIndex += 1;
                    patientNum = parseInt(newOrdersArray[i].record_id);
                    newOrdersArray[i]['patient_id_test'] = patientIndex;
                    // orderIndex = 1;
                    // newOrdersArray[i]['order_id_test'] = orderIndex;
                    // orderIndex++;
                } else {
                    newOrdersArray[i]['patient_id_test'] = patientIndex;
                    // newOrdersArray[i]['order_id_test'] = orderIndex;
                    // orderIndex++;
                }
                newOrdersArray[i]['order_id_test'] = (i + 1); // for numbering orders sequentially instead of per patient 
            }
            // console.log('new orders array with id/indexes', newOrdersArray);
            // newOrdersArray.forEach( o => console.log("patient id test " + o.patient_id_test + " order id " + o.order_id_test + " // record id " + o.record_id + " repeat_instance " + o.redcap_repeat_instance ))
            setDisplayOrders(newOrdersArray);
            setLoaded(true);
        }
        if (orders.length !== 0) {
            assignPatientIndex();
        }
    }, [orders])

    useEffect(() => {   
        // method checks if component is loaded(and data has been arranged properly), if there are existing responses in storage, and if it's already run  
        // and transfers any selections in storage to responses object
        const copyStorageToResponses = () => {
            let codeRegex = new RegExp(hashedCode, "g");
            let storageObject = {};
            Object.entries(storage).forEach( ([key, value]) => {
                if (key.includes(hashedCode)) {
                    // console.log('key, with hashcode', key);
                    key = key.replace(codeRegex, '');
                    storageObject[key] = value;
                }
            })
            // put all storage responses into responses ready to be scored
            setResponses({...storageObject});

            setTransferredStorageToResponses(true);
            }

        if (!transferredStorageToResponses && storage.length !== 0 && loaded) {
            copyStorageToResponses();
        }
    }, [hashedCode, storage, transferredStorageToResponses, responses, loaded])

    
    const renderRows = (order) => { // use the order category to find the index of the scoreSheet object, map the rows from that index
        const index = `${order.deception === "1" ? 'deception'+order.order_category_name : order.order_category_name}`
        // The following only looks for medRec subcategory information 
        let suffix = '';
        if (Number(order.subcategory) >= 4) { suffix = '-' + order.subcategory } // may need updating if other subcategories are added
        
        return <>
        {scoreSheet[`${index+suffix}`] === undefined ? console.log('error rendering rows, at order: ', order ) + console.log(" ScoreSheet Index: " + index) + setNoScoringAssigned(true) // handle error for index undefined with setNoScoringAssigned(true)
            : scoreSheet[`${index+suffix}`].map( (row, i) => {
                return  <Form.Check key={row.order_response_id} type="radio" value={`${row.order_response_id}-${order.patient_id_test}-${order.order_id_test}-${row.response_text}`} 
                            name={`Patient-${order.record_id}_Order-${order.redcap_repeat_instance}_Desc-${order.order_set_desc}`} 
                            defaultChecked={parseInt(order.test_response_index) === row.order_response_id ? true : false}
                            id={`order${order.order_id_test}_${i + 1}`}
                            label={`${row.response_text}`}
                            // class assigned by checking response object, necessary for testing
                            className={Object.values(responses).includes(`${row.order_response_id}-${order.patient_id_test}-${order.order_id_test}-${row.response_text}`) && 'checked-response'}
                            />
                })}
        </>
    }


    const handleSelect = (e, order) => {
        let { name, value } = e.target;
        // the responses object handles storing values to be scored
        let newChoice = {[name]: value};
        let prevResponses = responses;
        setResponses({...prevResponses, ...newChoice});
            
        // set local storage with each selection in case the window closes
        storage.setItem([name + hashedCode], value);

        // Remove red border from an order (filter it out of ordersMissingAnswers)
        if (ordersMissingAnswers.includes(order)) {
            let newOrdersMissingAnswers = ordersMissingAnswers.filter(o => o !== order)
            setOrdersMissingAnswers(newOrdersMissingAnswers)
            setNumMissingAnswers(newOrdersMissingAnswers.length)
            if (numMissingAnswers.lenth < 1) {setAlertMissing(false)}
            setDisplayOrders(displayOrders)
        }
    }   
    

    const handleOpenModal = () => {
        //sort the responses, use an array for the confirmation modal, use object for preparing the json
        let sorted = sortResponsesForDisplay(Object.entries(responses))
        //   console.log('sorted => becomes `DisplayResponses` :', sorted);
        setDisplayResponses(sorted)

        // Convert responses to array of objects in JSON format
        let responsesArrayForJson = [];
        Object.entries(responses).forEach(([key, value]) => {
            let singleResponse = {};
            // parse
            let patientNum = key.substring(key.indexOf("-") + 1, key.indexOf("_"));
            let orderNum = key.substring((key.indexOf("d") + 4), key.lastIndexOf("_"));
            let descrip = key.substring(key.indexOf("c") + 2, key.length);
            let responseNum = value.substring(0, value.indexOf("-"));
            let responseText = value.substring(value.search(CapitalLettersRegex), value.length)
            // assign
            singleResponse.patient = patientNum;
            singleResponse.order = orderNum;
            singleResponse.description = descrip;
            singleResponse.response_index = responseNum;
            singleResponse.response_text = responseText

            responsesArrayForJson.push(singleResponse);
        })
        // Store the responsesArrayForJson in state for making the scoring API request
        setResponsesToScore(JSON.stringify(responsesArrayForJson));

            setShowModal(true);
    }

    const handleSubmit = () => {
        setShowModal(false);
        setSubmitted(true)
        // include fetch here for submission of results
        fetch(getApiConfig("recordTestResponse"), {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                "access-code": accessCode,
                "test-response": responsesToScore
            }),
            })
            .then((response) => response.json())
            .then((data) => {
            // console.log('Answer post response data:', data);
            // check data for submission failure
                failureCheckObject(data) ? setSuccessful(true) : handleSubmitFailure()
            })
            .catch((error) => {
            console.error("Error:", error);
            });
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const handleSubmitFailure = () => {
        setErrorMessage(failureMessage);
        setSubmitted(false)
    }

    useEffect(() => {
        if (successful) {
            const handleAdvance = () => {
                // clear the local storage
                storage.clear();
                // set current step to next step
                setCurrentStep('/submitaffirmation');
                // setStepNumber(6)
                // redirect to next step
                history.push('/submitaffirmation');
            }
            handleAdvance();
        }
    }, [history, storage, successful, setCurrentStep, setStepNumber])


    const formatResponseString = (responseString) => {
        // Search for the first capital letter in the string and ditch everything in front of it
        let resultString = responseString.substring(responseString.search(CapitalLettersRegex));
        return resultString;
    }

    const formatKey = (k, v) => { // format the key/value into the display/test-specific order information
        let resultKey = 'Patient ';
        let pAndONumber = v.substring(v.indexOf("-") + 1, v.lastIndexOf("-")).split("-")
        resultKey += pAndONumber[0] + ", Order " + pAndONumber[1] + ", Desc: ";
        let orderDesc = k.substring(k.indexOf("c") + 2, k.length);
        resultKey += orderDesc;
        return resultKey
    }

    const buttonStyle = {
        marginTop: '1em',
        fontWeight: '300'
    }  

    const instructionsList = <>
    <p className="mt-2">
        You are now on <strong>Step 5</strong>. Record the responses from the Orders and Observations Sheet into the Online Answer Form below. <br /><br /><strong><span style={{color:'red'}}>Do not close your browser or window or navigate elsewhere until you complete and submit the Affirmation. <br /><br />If you close your browser or window or navigate elsewhere before submitting the Affirmation, your test will be scored as "Incomplete Evaluation" and you will not be able to retake the test for 120 days.</span></strong></p><p>Please note:</p>
    <ul>
        <li style={{marginTop: '10px'}}>Each Test Order from the Orders and Observations Sheet appears in the same order on the Online Answer Form.</li>
        <li style={{marginTop: '10px'}}>For each of the Test Orders, select the radio button that corresponds to the advice or information recorded by the prescriber on the Orders and Observations Sheet. Note that the specific radio button response options may differ from order to order. You may only select one radio button for each test order.</li>
        <li style={{marginTop: '10px'}}>If the Test Order could not be entered as specified (e.g., "Medication not electronically orderable in any formulation" or with the specified dose, frequency, or route), select the appropriate radio button. Otherwise, select the radio button which best describes the advice or information received by the prescriber (e.g., "Received advice/information concerning allergy to medication") or the absence of advice or information provided by the clinical decision support. If the prescriber did not receive the specific advice or information noted on the Answer Form for the Test Order, select the appropriate radio button (e.g., "No advice/information received concerning allergy to medication").</li>
        <li style={{marginTop: '10px'}}>As a reminder, not every Test Order should generate advice or information from the CPOE system.</li>
        <li style={{marginTop: '10px'}}>Once confident that the Online Answer Form is complete and accurate, submit the Online Answer Form by clicking "Submit and Proceed".</li>
        <li style={{marginTop: '10px'}}>Review your responses in the pop-up window, then click “Submit Responses” in order to move on to Step 6 Affirmation.</li>
    </ul>
    </>

    const validateAnswers = () => { // if user clicks submit without answering all order questions, isolate orders without answers
        let answers = Object.entries(responses);
        let ordersMissingAnswers = [...displayOrders]
        let numMissing = displayOrders.length - answers.length;
        console.log(`${numMissing} orders are missing a selection...`);
        setNumMissingAnswers(numMissing)
        setAlertMissing(true)
        let answerDescriptionsArray = []
        answers.forEach(answer => {
            let answString = answer[0].substring(answer[0].indexOf("D") + 5)
            answerDescriptionsArray.push(answString)
        })
        let resultArray = ordersMissingAnswers.filter(o => !answerDescriptionsArray.includes(o.order_set_desc))
        
        setOrdersMissingAnswers(resultArray)
        setDisplayOrders(displayOrders)
    }


    return(
        <Container className="mt-3 mb-3"
        style={{fontWeight: "300"}}>
            <Row>
                <Col sm={12}>
                    <h1>Step 5 of 7: Record Responses </h1>
                    <hr />
                    <Reminder />
                    { noScoringAssigned ? <p className="text-center" style={{color: 'red'}}>Your test is missing a scoring assignment and cannot continue.
                        <br/>Please logout and contact the test administrator.</p>
                    : displayOrders.length === 0 || (Object.keys(scoreSheet).length === 0 && failureCheckObject(scoreSheet)) ? 
                      <div style={{textAlign: 'center'}}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span> 
                        </Spinner>
                      </div>
                    : <>{instructionsList}
                    <Form>
                    <Table responsive>
                        <thead>
                            <tr>
                                <td>Pt. ID</td>
                                <td>Order #</td>
                                <td>Order(s)</td>
                                <td>Results from Orders and Observations Sheet</td>
                            </tr>
                        </thead>
                        <tbody id="responses-page-body">
                            {displayOrders.map( (order, i) => {
                                let specialidx = i + 1
                              return  <tr key={i} id={`res-row-${specialidx}`}>
                                        {/* <td>{order.record_id}</td> */}
                                        {/* This (below) is ignoring the native record_id and using the artificial patient index instead */}
                                        <td className="patient">{order.patient_id_test}</td> 
                                        {/* <td>{order.redcap_repeat_instance}</td> */}
                                        {/* This (below) is ignoring the native redcap_repeat_instance and using the artificial order index instead */}
                                        <td className="order">{order.order_id_test}</td>
                                        <td className="description">{order.order_set_desc}</td>
                                        <td style={{width: '70%'}}> 
                                            <div onChange={(event) => handleSelect(event, order)} 
                                            style={{ border: ordersMissingAnswers.includes(order) ? '1px solid red' : 'none',
                                                    borderRadius: ordersMissingAnswers.includes(order) ? '5px' : 'none'
                                                    }}
                                            >
                                                {renderRows(order)}
                                            </div>
                                        </td>
                                        </tr>
                            })}
                        </tbody>
                    </Table>
                    </Form>
                    </>
                }
                {numMissingAnswers >= 1 ? <div>
                    
                        <Toast bg='warning' variant='warning' onClose={() => setAlertMissing(false)} show={alertMissing} delay={5000} autohide>
                            <Toast.Header>
                                <strong className="mr-auto">Attention:</strong>
                            </Toast.Header>
                            <Toast.Body style={{backgroundColor: '#ffc107'}} bg='warning' variant='warning'>{numMissingAnswers > 1 ? `There are ${numMissingAnswers} orders missing responses.` : 
                                `There is ${numMissingAnswers} order missing a response.` } <br />
                                Scroll up to see missing orders highlighted in red.
                            </Toast.Body>
                        </Toast>
                    
                </div> : ''}
                    <div style={{textAlign: 'center'}}>
                        {errorMessage && errorMessage}
                        {Object.entries(responses).length !== displayOrders.length ? 
                        <Button 
                            style={{marginTop: '1em', opacity: 0.4, fontWeight: '300'}} 
                            className='px-3'
                            onClick={validateAnswers} 
                            variant='info'>Submit and Proceed</Button>
                    : submitted ?   
                    <Button
                        style={buttonStyle}
                        // onClick={handleOpenModal}
                        className="px-3"
                        variant='info'
                        ><Spinner animation="border" size="sm" variant="light" role="status">
                            <span className="sr-only">Submitting Results...</span> 
                         </Spinner>
                            <span>&nbsp;  </span>
                            Submitting Results...
                        </Button>
                    :<Button
                        style={buttonStyle}
                        onClick={handleOpenModal}
                        className="px-3"
                        variant="info"
                        >Submit and Proceed
                        {/* Proceed to Next Step */}
                        </Button>
                    }
                    </div>    
                </Col>
            </Row>
            <Modal size='lg' show={showModal} animation={false} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Review Responses</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Review responses before submitting:
            <div style={{ height: "400px", overflow: "scroll" }}>
                <Table>
                    <thead>
                        <tr>
                            <td><strong>Pt #, Order #, Description</strong></td>
                            <td><strong>Observation Response</strong></td>
                        </tr>
                    </thead>
                    <tbody id="modal-body">
                        {displayResponses.length === 0 ? '' : displayResponses.map(([key, value]) => {
                            return <tr key={key} className="modal-row">
                                     <td className="modal-order">{formatKey(key, value)}</td>
                                     <td>{formatResponseString(value)}</td>
                                   </tr>
                        })}
                    </tbody>
                </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSubmit}> 
              Submit Responses
            </Button>
          </Modal.Footer>
        </Modal>
        </Container>
    )
}

export default RecordResponses
