import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const InstructionsTable = ({testType}) => {
  return (
    <Row>
      <Col>
        <p>
            The CPOE Evaluation Tool, which includes the practice Sample Test and the scored Adult {testType} Test, moved to this new platform on September 12.
            While the steps, time limits, Order Checking Categories, and scoring algorithm for the test remain the same, the navigation options have been updated to reflect this new platform. 
            As such, all hospitals are strongly encouraged to review the <a href="https://www.leapfroggroup.org/survey-materials/survey-and-cpoe-materials" target="_blank" rel="noreferrer">CPOE Tool Instructions</a> and complete a <LinkContainer to="/sampledashboard"><Link href="/">practice Sample Test</Link></LinkContainer> prior to starting the scored Adult {testType} Test.           
        </p>
        <p>
        <strong>Important Notes for Hospitals That Have <u>Already Taken</u> the CPOE Test for the 2022 Survey Cycle:</strong>
        <ol style={{listStyleType:"number"}}>
          <li><u>You do not need to re-take a test.</u><br />
          Re-taking an Adult {testType} Test is optional and only recommended for hospitals that have made major updates to their CPOE system since the last time they took a test in the 2022 Survey Cycle. 
          The technology update has no impact on your current CPOE Test score or your current publicly reported <a href="https://ratings.leapfroggroup.org/" target="_blank" rel="noreferrer">Survey Results.</a>
          </li>
          <li><u>CPOE Test Results prior to September 11.</u><br />
          Hospitals that completed a CPOE Test prior to September 11 are not able to access previous Test Results on this new platform. 
          If you would like a copy of your previous Test Results, please submit a ticket to the <a href="https://leapfroghelpdesk.zendesk.com/hc/en-us" target="_blank" rel="noreferrer">Leapfrog Help Desk.</a>
        </li>
        </ol>
        </p>
        <h5>Time Limits and Steps</h5>
        <p>
          <strong>
            If you fail to complete the steps within the time limits, the Adult {testType} Test
            will be scored "Incomplete Evaluation", and you will not be able to
            take this Test again for 120 days.
          </strong>
        </p>
        <Table bordered>
          <thead>
            <tr>
              <td style={{ width: "20%" }}>
                <strong>Time Limits</strong>
              </td>
              <td style={{ width: "80%" }}>
                <strong>Steps</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Steps 1 - 2 must be completed within <strong>3 hours</strong>.
              </td>
              <td>
                <strong>Step 1:</strong> Download and print the list of the Test Patients, then proceed to Step 2.
                <br /><br />
                <strong>Step 2:</strong> Enter the Test Patients into your
                hospital's production environment (or test environment that
                mirrors your production environment exactly), then go back to the Tool to confirm completion of Step 2 and proceed to Step 3 before the 3-hour time limit ends.
              </td>
            </tr>
            <tr>
              <td>
                Steps 3 - 6 must be completed within <strong>3 hours</strong>.
                <br /><br />
                <span style={{ color: "red" }}>Important Note 1: </span> Any
                remaining time from steps 1 and 2 does{" "}
                <strong>
                  <u>not</u>
                </strong>{" "}
                carry over to this portion of the test.
                <br /><br />
                <span style={{ color: "red" }}>Important Note 2: </span> We
                recommend leaving at least <strong>45 minutes</strong> to
                perform Step 5.
              </td>
              <td>
                <strong>Step 3:</strong> Download and print the Orders and Observations Sheets, then proceed to Step 4.
                <br /><br />
                <strong>Step 4:</strong> Have a prescriber who routinely
                orders medications through your inpatient CPOE system enter and
                sign the test order(s) assigned to each Test Patient. The
                prescriber must record any advice or information he/she receives
                from the system onto the Orders and Observations Sheets and
                discontinue the test order(s) before moving onto the next order.
                <br /><br /> 
                Note: Do not record any advice or information on the
                Orders and Observations Sheets that was sent directly to a
                pharmacist.
                <br /><br />
                Once advice or information for each of the Test Orders has been recorded on the sheet, go back to the Tool to confirm completion of Step 4 and proceed to Step 5.
                <br /><br />
                <strong>Step 5:</strong> Record the responses from the
                Orders and Observations Sheets onto the Online Answer Form, 
                review the Online Answer Form for accuracy and completeness, 
                and submit the Online Answer Form.
                <br /><br />
                <strong>Step 6:</strong> Complete and submit the Affirmation.{" "}
                <br /><br />
                <strong>IMPORTANT NOTICE: </strong>If you close the browser
                before submitting the Affirmation you will not be able to return
                to the Test for 120 days and your hospital will receive a score
                of "Incomplete Evaluation".
              </td>
            </tr>
            <tr>
              <td>Step 7 has no time limit.</td>
              <td>
                <strong>Step 7:</strong> View and print your results.
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default InstructionsTable;
