import React, { useContext } from "react";
import { UserContext } from '../../context/UserContextProvider'

const RedText = () => {
  
    const { testType } = useContext(UserContext)

    return(
      <p className="mt-3" style={{color: 'red'}}>
        <strong>DO NOT</strong> close this window or browser or navigate elsewhere during the Adult {testType} Test.
      </p>
    )
}

export default RedText