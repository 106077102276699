import React, {useContext, useState} from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { useHistory } from "react-router-dom";
import { UserContext } from '../context/UserContextProvider';
import Reminder from '../pages/tools/Reminder'
// import GreyCard from './tools/GreyCard';
import RedText from './tools/RedText';
import useDisableBackButton from "./hooks/useDisableBackButton";
import useSetCurrentStep from './hooks/useSetCurrentStep';

const EnterOrders = () => {

    const [isChecked, setIsChecked] = useState(false)
    const { setCurrentStep, deadline, isSampleTest, sampleTestDeadline } = useContext(UserContext);

    const history = useHistory();

    useDisableBackButton()  // Disable the browser's back button 
    useSetCurrentStep("ENTER_ORDERS", 4)

    const handleAdvance = () => {
        // set current step to next step
        setCurrentStep('/recordresponses');
        // redirect to next step
        history.push('/recordresponses');
    }
  
    const buttonStyle = {
        marginTop: '1em',
        fontWeight: '300'
    }  

    return(
        <Container className="mt-3 mb-5 pt-3 pb-3"
        style={{fontWeight: "300"}}>
            <Row>
                <Col sm={12}>
                    <h1>Step 4 of 7: Enter Orders and Complete Observations Sheet</h1>
                    <hr />
                    <Reminder />
                    {/* LF requested removal of this element in April 1 text updates */}
                    {/* <GreyCard text="If you have not already entered the Test Orders into your hospital system and recorded the responses on your Orders and Observations Sheet, please do so now." /> */}
                    <p className="mt-2">To complete the test, you will need to:</p>
                        <ul>
                            <li>Complete <strong>Step 4</strong> by confirming that you have entered the Test Orders, recorded any advice or information given to the prescriber, and are ready to begin Step 5.</li>
                            <li>Then, complete <strong>Step 5 Record Responses</strong> and <strong>Step 6 Submit Affirmation</strong> by <strong>{isSampleTest ? sampleTestDeadline : deadline} EDT</strong>.</li>
                        </ul>
                    <RedText />
                    <Card>
                        <Card.Body>
                            <Form>
                                <Form.Check 
                                    type="checkbox" 
                                    label={<strong>Yes, I have entered the Test Orders, recorded any advice or information given to the prescriber and I am ready to begin Step 5.</strong>}
                                    onClick={() => setIsChecked(!isChecked)}
                                    checked={isChecked}
                                    />
                            </Form>
                        </Card.Body>
                    </Card>
                    <div style={{textAlign: 'center'}}>
                        <Button
                        style={buttonStyle}
                        disabled={isChecked ? false : true}
                        onClick={handleAdvance}
                        className="px-3"
                        variant="info"
                        >
                        Proceed to Step 5
                        </Button>
                    </div>    
                </Col>
            </Row>
        </Container>
    )
}

export default EnterOrders
