import React, {useState, useContext} from 'react'
import { UserContext } from '../../context/UserContextProvider'
import Accordion from 'react-bootstrap/Accordion' 
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import Button from "react-bootstrap/Button";
import {LinkContainer} from "react-router-bootstrap";
import {Link} from "react-router-dom";
import {convertTimeLocal} from "../../globals";

const TestHistory = () => {
    
    const {accessCode, isSampleTest, getApiConfig} = useContext(UserContext); // will need for fetching testHistory from API
    const [fetchingHistory, setFetchingHistory] = useState(false)
    const [testHistory, setTestHistory] = useState([])
    const [historyOpen, setHistoryOpen] = useState(false)


    const getTestHistory = () => {
        setHistoryOpen(!historyOpen)
        if (testHistory.length === 0) {
        setFetchingHistory(true)
        console.log('Fetching test history ' + getApiConfig("getBob"));
        fetch(getApiConfig("getSiteTestHistory"), {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
          },
          body: JSON.stringify({"access-code": accessCode}),
        })
        .then((response) => response.json())
        .then((data) => {
          console.log("***test history:", data);
          // console.log('current test access code', accessCode);
          if (data.length) {
            // filtering out tests that don't have an scoreTime time (not completed), sorting in reverse chronological order
            // AND don't show the current test (on the last step it's possible the current test would show up in test history)
            // if seeing the current test in the history is desired, remove `&& test.access_code !== accessCode` from next line
            // TODO: must see timed out tests too, not just filtered tests ... all completed tests
            let onlyCompletedTests = data.filter(test => test.scoreTime !== null && test.accessCode !== accessCode)
            let reverseChronologicalTests = onlyCompletedTests.sort((a,b) =>  { return Date.parse(b.scoreTime) - Date.parse(a.scoreTime) })
            setTestHistory(reverseChronologicalTests) 
            setFetchingHistory(false)
          } else { setTestHistory([]) }
          })
          .catch((error) => {
            console.error("Error getting test history:", error);
          });
        }
    }

    return(
            <Accordion>     
              <Card eventKey="0" className="mb-3">
                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={getTestHistory} className="point">
                  <span 
                  // style={{float: 'right'}}
                  >{historyOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}{" "}</span>
                  <span className="ml-1">Previous Test History</span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                  {isSampleTest ?  <p>If this were a real test, your previous scores would be displayed here.</p> : 
                  fetchingHistory ? 
                    <div style={{width: '100%', height: '300px', textAlign: 'center'}}>
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading Test History...</span> 
                      </Spinner>
                    </div>
                  : testHistory.length > 0 ? <Table>
                    <thead>
                      <tr>
                        <td>Date Test Completed</td>
                        <td>Test Type</td>
                        <td>Test Status</td>
                        <td>View Results</td>
                      </tr>
                    </thead>
                    <tbody>
                      {testHistory.map((test, i) => {
                        return <tr key={i}>
                            <td>{convertTimeLocal(test.scoreTime)}</td>
                            <td>{test.examType}</td>
                            <td>{test.status}</td>
                            <td><Link to={{
                                pathname: '/viewhistoricalresults',
                                hist_access_code: test.accessCode
                            }}
                            >Results</Link></td>
                          </tr>
                      })}
                    </tbody>
                  </Table> : 'No Previous Test History'
                  }
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
    )
}

export default TestHistory